import React, { useState } from "react";
import { Form, Input, Button, message, Carousel } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import './index.less';

function Page() {

  // const content = [
  //   {
  //     "title": "全渠道数据整合",
  //     "tip": "数据整合，身份归一，可分析可洞察可运营",
  //     "url": "https://oss.huizustore.com/ecfa821320db4793886e90d63ec025c0.png"
  //   },
  //   {
  //     "title": "全方位洞察用户",
  //     "tip": "多维画像，数据分析，结果可视化，决策智能化",
  //     "url": "https://oss.huizustore.com/55eb1c9172b34e4b8b499aa396cc2e28.png"
  //   },
  //   {
  //     "title": "打造私域流量",
  //     "tip": "沉淀客户资产，降低获客成本",
  //     "url": "https://oss.huizustore.com/3542fdc88d064d7a954c63ea697aa6c7.png"
  //   },
  //   {
  //     "title": "全流程精细化运营",
  //     "tip": "用户全生命流程覆盖，可触达可运营",
  //     "url": "https://oss.huizustore.com/8050bccd87824d04a5e472a1afc16d2e.png"
  //   },
  // ]

  // const pics = [
  //   "https://oss.huizustore.com/cc9b134cdb3a4360ba41d192a47d7847.png",
  //   "https://oss.huizustore.com/578d54772a074101b2dc19b94d1dd6bd.png",
  //   "https://oss.huizustore.com/fcfd8c3b44024a998ca51b554225e7e0.png",
  // ]

  const [form] = Form.useForm()
  const [isShowBox, setIsShowBox] = useState("block")
  const [isShowModal, setIsShowMdal] = useState("none")
  const [showBack, setShowBack] = useState("")
  const [opacity, setOpacity] = useState(1)
  const [isShowTip, setIsShowTip] = useState(true);
  const [isShowTip1, setIsShowTip1] = useState(true);
  const [isShowTip2, setIsShowTip2] = useState(true);
  const [isShowTip3, setIsShowTip3] = useState(true);

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const cancelModal = () => {
    form.resetFields();
    setIsShowMdal("none")
  }

  const onFinish = (values) => {
    console.log("666", values);
    // form.resetFields();
    submitForm({
      ...values
    }).then(res => {
      // console.log("666", res)
      if (res.data.code === 1) {
        message.success(res.data.msg);
        cancelModal()
      } else {
        message.error(res.data.msg);
        cancelModal()
      }
    });
  };

  window.onscroll = function () {
    // console.log(window.scrollY);
    if (window.scrollY > 140) {
      setShowBack("#000000")
      setOpacity(0.8)
      // console.log("666")
    } else {
      setShowBack("")
      setOpacity(1)
    }
  }

  return (
    <div className="page">
      <div className="mask"></div>
      {/* <iframe src="https://oss.huizustore.com/b22d042352de440ea5e636bc4ea2b511.mp4" className="background" scrolling="no" frameBorder="0" /> */}
      {/* <video src="https://oss.huizustore.com/b22d042352de440ea5e636bc4ea2b511.mp4" controls className="background"></video> */}
      <div className="background"></div>
      <div className="box-fix" style={{ display: isShowBox }}>
        <div className="title">微信咨询 </div>
        <div className="icon" onClick={() => setIsShowBox("none")}><CloseOutlined /></div>
        <div className="pic"></div>
        <div className="tip">联系客户经理，立即咨询</div>
      </div>
      <div className="modal-back" style={{ display: isShowModal }}>
        <div className="box-modal" >
          <div className="title">立即预约</div>
          <div className="icon" onClick={cancelModal}><CloseOutlined /></div>
          <div className="form">
            <Form form={form} onFinish={onFinish} autoComplete="off">
              <div className="lable">姓<span style={{ visibility: "hidden" }}>姓名</span>名：</div>
              <Form.Item name="contactName" rules={[{ required: true, message: '您的姓名为必填' }]}>
                <Input placeholder={isShowTip ? "请输入您的姓名" : ''} className="input" style={{ display: "inline-block" }} onFocus={() => setIsShowTip(false)} onBlur={() => setIsShowTip(true)} />
              </Form.Item>
              <div className="lable">企业名称：</div>
              <Form.Item name="enterpriseName" rules={[{ required: true, message: '企业名称为必填' }]}>
                <Input placeholder={isShowTip1 ? "请输入企业名称" : ''} className="input" style={{ display: "inline-block" }} onFocus={() => setIsShowTip1(false)} onBlur={() => setIsShowTip1(true)} />
              </Form.Item>
              <div className="lable">手机号码：</div>
              <Form.Item name="telephone" rules={[
                {
                  pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
                  required: true,
                  message: '请输入正确的手机号码!',
                },
              ]}>
                <Input placeholder={isShowTip2 ? "请输入手机号码" : ''} className="input" style={{ display: "inline-block" }} onFocus={() => setIsShowTip2(false)} onBlur={() => setIsShowTip2(true)} />
              </Form.Item>
              <div className="lable">主营业务：</div>
              <Form.Item name="categories">
                <Input placeholder={isShowTip3 ? "请输入意向类目，如：手机" : ''} className="input" style={{ display: "inline-block" }} onFocus={() => setIsShowTip3(false)} onBlur={() => setIsShowTip3(true)} />
              </Form.Item>
              <Form.Item>
                <Button className="button" htmlType="submit">提交信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className="nav" style={{ background: showBack, opacity: opacity }}>
        <div className="nav-logo"></div>
        <div className="nav-box"></div>
        <div className="nav-nav">
          <div className="nav-button" onClick={() => setIsShowMdal("block")}>免费试用</div>
          <div><a className="nav-title" href="#about">关于我们</a></div>
          <div><a className="nav-title" href="#product">产品</a></div>
          <div><a className="nav-title" href="#index">首页</a></div>
        </div>
      </div>
      <div className="header" id="index">
        {/* <div className="header-left"></div> */}
        <div className="header-center">
          <div className="tip-box">
            <div className="tip-green"></div>
            <div className="tip-biue"></div>
          </div>
          <div className="title-box">
            <div className="triangle"></div>
            <div className="title">每一个客户都值得认真对待</div>
            <div className="circle"></div>
            <div className="circle1"></div>
          </div>
          <div className="line">
            <div className="line-icon"></div>
          </div>
          <div className="tip">
            全渠道客户数据管理+私域精细化用户运营
          </div>
          <div className="button-box">
            <div className="button" onClick={() => setIsShowMdal("block")}>立即使用</div>
          </div>
          <div className="tip-boxs">
            <div className="tip-purple"></div>
            <div className="tip-yellow"></div>
          </div>
        </div>
        {/* <div className="header-right"></div> */}
      </div>
      <div className="entry-box1"></div>
      <div className="product" id="product">
        <div className="product-left"></div>
        <div className="product-center">
          <div className="title">
            让客户运营更简单
            <div className="purpleLine"></div>
            <div className="green"></div>
            <div className="green1"></div>
          </div>
          <div className="tip">全域营销，助你业务快速增长</div>
          <div className="commit">
            <div className="commitItem">
              <div className="icon">
              </div>
              <div className="title">全渠道数据整合</div>
              <div className="tip">数据整合，身份归一，可分析可洞察可运营</div>
            </div>
            <div className="commitItem">
              <div className="icon1">
              </div>
              <div className="title">全方位洞察用户</div>
              <div className="tip">多维画像，数据分析，结果可视化，决策智能化</div>
            </div>
            <div className="commitItem">
              <div className="icon2">
              </div>
              <div className="title">打造私域流量</div>
              <div className="tip">沉淀客户资产，降低获客成本</div>
            </div>
            <div className="commitItem">
              <div className="icon3">
              </div>
              <div className="title">全流程精细化运营</div>
              <div className="tip">用户全生命流程覆盖，可触达可运营</div>
            </div>
          </div>
        </div>
        <div className="product-right"></div>
      </div>
      <div className="entry-box2"></div>
      <div className="picture">
        <div className="pic-left">
        </div>
        <div className="pic-center">
          <div className="pic">
            <div className="content">
              <Carousel autoplay dots={false}>
                <div className="carousel1">
                  <img style={{ width: "100%", height: "100%" }} src={"https://oss.huizustore.com/e476dbbb6df2443794fb58fdb2ce1d64.png"} preview={false} />
                </div>
                <div className="carousel2">
                  <img style={{ width: "100%", height: "100%" }} src={"https://oss.huizustore.com/01008d0ffeba49c3b1565105e5f5ebf9.png"} preview={false} />
                </div>
                <div className="carousel3">
                  <img style={{ width: "100%", height: "100%" }} src={"https://oss.huizustore.com/ee72c2e80fea40228aa06fe8873db975.png"} preview={false} />
                </div>
                <div className="carousel3">
                  <img style={{ width: "100%", height: "100%" }} src={"https://oss.huizustore.com/8ce5a205660e4550b0286f82c23491a9.png"} preview={false} />
                </div>
              </Carousel>
            </div>
          </div>
          <div className="pic-box"></div>
          <div className="pic-content">
            <p>妙鸦平台，提供商家<br /><span style={{ color: "#3562EC" }}>全域管理</span>客户的能力<br />助力<span style={{ color: "#2BA45D" }}>精准运营</span>，实现<br /><span style={{ color: "#f6845a" }}>高效沟通</span>，体现<span style={{ color: "#725AF9" }}>流量<br />价值<br /></span>帮助商家整合所有线<br />上客户资源，最终打<br />造形成完整的<span style={{ color: "#F89927" }}>私域增<br />长</span>闭环</p>
          </div>
        </div>
        <div className="pic-right"></div>
      </div>
      <div className="entry-box3"></div>
      <div className="aboutUs" id="about">
        <div className="Us-left"></div>
        <div className="Us-center">
          <div className="title">关于我们</div>
          <div className="tip">MarTech领域领先的技术服务提供商</div>
          <div className="content-title">公司介绍</div>
          <div className="content">杭州脚本信息技术有限公司成立于2019年，核心团队来自阿里巴巴及电子科技大学；拥有数十项知识产权，是一家已获得国科小/人工智能小镇项目。杭州市雏鹰计划等荣誉认定的高新技术企业，旗下拥有“妙鸦”和“惠租”为国内领先的免押金信用租赁SaaS云服务平台。脚本科技在商户数及规模上具有显著行业领先优势</div>
          <div className="pic-box">
            <div className="pic-left">
              <div className="content"></div>
            </div>
            <div className="pic-right">
              <div className="content"></div>
            </div>
          </div>
        </div>
        <div className="Us-right"></div>
      </div>
      <div className="entry-box4"></div>
      <div className="action">
        <div className="title">准备好开始了么</div>
        <div className="tip">每一个客户都是你的宝贵财富</div>
        <div className="button" onClick={() => setIsShowMdal("block")}>立即开始</div>
      </div>
      <div className="entry-box5"></div>
      <div className="footer">
        <div className="line"></div>
        <div className="content">联系方式：0571-26881669（人工服务：9:30-12:00  14:00-18:00（工作日）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;商务/渠道及其它合作：jiaoben@yichain-tech.com&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;电信业务经营许可证：浙B2-20190991</div>
        <div className="content">备案/许可证号：<a href="https://beian.miit.gov.cn" target="_blank" >浙ICP备19009847号-7</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@2024杭州脚本信息技术有限公司 版权所有</div>
      </div>
    </div>
  )
}

export default Page;